import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Row, Column, Box, Button, Text } from 'components'
import { useSharedState } from 'context/sharedState'
import { createLead } from 'api-utils/lead'
import { useLocation } from 'context/location'
import { brochureSignupValidationSchema } from 'utils/forms'
import loadable from '@loadable/component'
import {
  Input,
  Checkbox,
  SubmissionFailedErrorMessage,
  Radio,
  Label,
} from 'components/Forms'
import LocationSelect from 'components/LocationSelect'
import Modal from 'components/Calculator/Modal'
import { SALUTATION_OPTIONS } from 'models/Options'
import { ErrorMessage } from 'components/Forms/Errors'

const CountryCodeSelect = loadable(() =>
  import('components/Forms/CountryCodeSelect')
)

const Select = loadable(() => import('components/Forms/BambusSelect'))

const Lead = ({ onClose }) => {
  const { countryCode } = useLocation()

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const [lead, updateLead] = useSharedState()
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    errors,
    control,
    watch,
  } = useForm({
    resolver: brochureSignupValidationSchema,
    defaultValues: {
      initial_signup_reason: 'brochure_email',
    },
  })

  const initialSignupReason = watch('initial_signup_reason')

  React.useEffect(() => {
    if (getValues('telephone')) {
      return
    }

    if (selectedCountry) {
      setValue('telephone_prefix', selectedCountry.prefix)
      return
    }

    if (countryCode) {
      setValue('telephone_prefix', countryCode === 'DE' ? '+49' : '+43')
      return
    }
  }, [selectedCountry, countryCode])

  const onSubmit = async (data) => {
    setLoading(true)

    const telephonePrefix = data.telephone_prefix
    const telephone = data.telephone
    //delete the telephone & telephone_prefix from data as we don't want it spread into the payload
    delete data['telephone_prefix']
    delete data['telephone']

    if (!data.email) {
      const date = new Date()
      data.email = `${data.last_name}+${date.getUTCDate()}${
        date.getUTCMonth() + 1
      }${date.getUTCFullYear()}${date.getHours()}${date.getMinutes()}@bambus.services`
    }

    const payload = {
      ...lead,
      lead: {
        ...lead.lead,
        ...data,
        ...(telephone && {
          telephone: `${telephonePrefix} ${telephone
            .replace(/\ /g, '')
            .replace(/\-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}`,
        }),
        property_country:
          selectedCountry?.value ??
          (countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode),
        application_country:
          countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode,
      },
      customEventPayload: {},
    }

    const { success, errors } = await createLead(payload)

    if (errors) {
      errors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      setShowSuccess(true)
      updateLead({})
    } else {
      setShowError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    register('salutation')
  }, [])

  return (
    <Modal
      title={
        showSuccess
          ? 'Vielen Dank für Ihre Anfrage! Ein Mitarbeiter wird sich zum Infopaket mit Ihnen in Verbindung setzen.'
          : 'Infopaket anfordern'
      }
      onClose={onClose}
      hideBody={showSuccess}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column size={[1, 1, 1, 1]}>
            <Box sx={{ display: 'flex' }} pt="8px" pb="8px">
              <Label
                sx={{
                  padding: '12px',
                  cursor: 'pointer',
                  width: 'auto',
                  border: '1px solid',
                  borderColor: 'colorBlue25',
                  borderRadius: 'default',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                  fontSize: [1, 2],
                  flex: 1,
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  alignItems: 'center',
                  textAlign: ['center', 'start'],
                }}
                color="colorBlue100"
              >
                <Radio
                  name="initial_signup_reason"
                  id="brochure_email"
                  value="brochure_email"
                  ref={register}
                  sx={{ color: 'colorBlue100', width: '20px' }}
                />
                Infopaket per E-Mail
              </Label>

              <Label
                sx={{
                  padding: '12px',
                  cursor: 'pointer',
                  width: 'auto',
                  border: '1px solid',
                  borderLeft: 'none',
                  borderColor: 'colorBlue25',
                  borderRadius: 'default',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  fontSize: [1, 2],
                  flex: 1,
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  alignItems: 'center',
                  textAlign: ['center', 'start'],
                }}
                color="colorBlue100"
              >
                <Radio
                  name="initial_signup_reason"
                  id="brochure_post"
                  value="brochure_post"
                  ref={register}
                  sx={{ color: 'colorBlue100', width: '20px' }}
                />
                Infopaket per Post
              </Label>
            </Box>
          </Column>
        </Row>

        <Row>
          <Column size={[1, null, null, 1 / 2]}>
            <Box py={2}>
              <Select
                options={SALUTATION_OPTIONS}
                onChange={(option) => {
                  setValue('salutation', option.value, {
                    shouldValidate: true,
                  })
                }}
                placeholder="Anrede"
                hasError={errors.salutation}
                isSearchable={false}
              />
              {errors.salutation && (
                <ErrorMessage message={errors.salutation.message} />
              )}
            </Box>
          </Column>

          <Column size={[1, null, null, 1 / 2]}>
            <Box py={2}>
              <Input
                type="hidden"
                name="form_version"
                value="1"
                ref={register}
              />
              <Input
                placeholder="Vorname"
                name="first_name"
                ref={register}
                error={errors.first_name}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column size={[1, null, null, 1 / 2]}>
            <Box py={2}>
              <Input
                placeholder="Nachname"
                name="last_name"
                ref={register}
                error={errors.last_name}
              />
            </Box>
          </Column>
          <Column size={[1, null, null, 1 / 2]}>
            <Box py={2}>
              <Input
                placeholder="E-Mail"
                name="email"
                ref={register}
                error={errors.email}
                {...(initialSignupReason === 'brochure_post' && {
                  badge: 'optional',
                })}
              />
            </Box>
          </Column>
        </Row>

        <Row>
          <Column size={[1, null, null, 1 / 2]}>
            <Box
              py={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: '112px auto',
                gridTemplateRows: 'auto auto',
                gridTemplateAreas: `
                    'telephonePrefix telephone'
                    'errorMessage errorMessage'
                  `,
              }}
            >
              <Box sx={{ gridArea: 'telephonePrefix' }}>
                <Controller
                  as={CountryCodeSelect}
                  name="telephone_prefix"
                  register={register}
                  control={control}
                  defaultValue={countryCode === 'DE' ? '+49' : '+43'}
                />
              </Box>
              <Input
                placeholder="Telefonnummer"
                name="telephone"
                ref={register}
                error={errors.telephone}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: 'none',
                  gridArea: 'telephone',
                }}
                {...(initialSignupReason === 'brochure_email' && {
                  badge: 'optional',
                })}
              />
            </Box>
          </Column>
        </Row>

        {initialSignupReason === 'brochure_post' && (
          <>
            <Box pt={2}>
              <Text
                sx={{
                  fontWeight: '700',
                  fontSize: '16px',
                }}
              >
                Anschrift
              </Text>
            </Box>

            <Row>
              <Column
                size={[1, null, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
              >
                <Box py={2}>
                  <Input
                    placeholder="Straße"
                    name="street_name"
                    ref={register}
                    error={errors.street_name}
                  />
                </Box>
              </Column>
              <Column
                size={[1, null, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
              >
                <Box py={2}>
                  <Input
                    placeholder="Hausnummer"
                    name="house_number"
                    ref={register}
                    error={errors.house_number}
                  />
                </Box>
              </Column>
            </Row>

            <Row>
              <Column
                size={[1, null, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
              >
                <Box py={2}>
                  <Input
                    placeholder="Stadt"
                    name="city"
                    ref={register}
                    error={errors.city}
                  />
                </Box>
              </Column>
              <Column
                size={[1, null, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
              >
                <Box py={2}>
                  <Input
                    placeholder="PLZ"
                    name="zip"
                    ref={register}
                    error={errors.zip}
                  />
                </Box>
              </Column>
            </Row>

            <Box py={2}>
              <LocationSelect
                value={selectedCountry?.value}
                onChange={setSelectedCountry}
                noLabel
              />
            </Box>
          </>
        )}

        <Box py={2}>
          <Checkbox
            label="Hiermit bestätige ich bis auf Widerruf, dass Bambus meine Daten zur Bearbeitung meiner Anfrage speichern darf."
            name="consent_box"
            ref={register}
            error={errors.consent_box}
          />
        </Box>
        <Box mt={2}>
          {showError && <SubmissionFailedErrorMessage />}
          <Button
            id="modalRequestComplete"
            variant="main"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Weiter
          </Button>
        </Box>
      </form>
    </Modal>
  )
}

export default Lead
