import React, { useEffect, useRef } from 'react'
import { X as Close } from 'icons'

import { Root, Box, Flex, Heading, Icon } from 'components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import useKeyPressEvent from 'hooks/useKeyPressEvent'

const Modal = ({ hideBody, title, onClose, children }) => {
  const ref = useRef()

  useKeyPressEvent('Escape', onClose)

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current, { allowTouchMove: false })
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <Root>
      <Box ref={ref} variant="modal.container">
        <Box variant="modal.dialog">
          <Flex py={3}>
            <Heading.H4 flexGrow={1} pr={3}>
              {title}
            </Heading.H4>
            <Box
              my={-2}
              px={-1}
              flexShrink={0}
              sx={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <Box py={2} px={1}>
                <Icon source={Close} color="colorBlue25" size={18} mt="4px" />
              </Box>
            </Box>
          </Flex>
          {!hideBody && (
            <Box pb={4} maxWidth={680}>
              {children}
            </Box>
          )}
        </Box>
        <Box variant="modal.backdrop" />
      </Box>
    </Root>
  )
}

export default Modal
